import BaseService from "./BaseService";

export default class ImagemService extends BaseService {
    getAll() {
        const request = this.requester.get(`${this.url}/api/imagens`, this.config).then(response => response).catch(e => {
            throw e
        }).finally(() => {
        })
        return request;
    }

    new(param) {
        const request = this.requester.post(`${this.url}/api/imagem`, param, this.configFormData).then(response => response).catch(e => {
            throw e
        }).finally(() => {
        })
        return request;
    }

    remove(id) {
        const request = this.requester.delete(`${this.url}/api/imagem/${id}`, {}, this.config).then(response => response).catch(e => {
            throw e
        }).finally(() => {
        })
        return request;
    }
}
