<template>
    <div class="job-page">
        <div class="row mt-5 mb-5">
            <div class="col-md-12"><h3>Imagens</h3></div>
        </div>
        <div class="mt-5 mb-5">
            <button class="btn btn-success" @click="openModalNew()">Cadastrar Imagem</button>
        </div>
        <div class="row">
            <table class="table table-warning border border-dark table-bordered">
                <thead >
                    <tr>
                        <th>Titulo</th>
                        <th>URL</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(imagem,index) in imagens" :key="index">
                        <td class='align-middle'>{{ imagem.title }}</td>
                        <td class='align-middle'>{{ imagem.url }}</td>
                        <td class='align-middle'>
                            <button  class="btn btn-primary btn-sm me-2" @click="openModalView(imagem)">Visualizar</button>
                            <button  class="btn btn-danger btn-sm" @click="openModalRemove(imagem)">Excluir</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Modal v-if="openedModalNew" :openModal="openedModalNew" :size="'md'" :classCustom="'modal-recuperar-senha'" name="nome" >
            <b-row>
                <b-col class="d-flex align-items-center justify-content-end">
                    <div class="wrapper-icon-modal d-flex align-items-center justify-content-center" >
                        <b-icon @click="openedModalNew = false" icon="x" ></b-icon>
                    </div>
                </b-col>
            </b-row>
            <div class="d-flex align-items-center justify-content-center flex-column">
                <h2>Adicionar Imagem</h2>
                <p class="my-4">Selecione o arquivo</p>
                <input type="file" class="form-control" @change="setImage($event)"  accept="image/png, image/jpeg">
                <button class="btn btn-success mt-3 " @click="saveImage">Enviar</button>
            </div>
        </Modal>

        <Modal v-if="openedModalRemove" :openModal="openedModalRemove" :size="'lg'"  :classCustom="'modal-recuperar-senha'"  name="nome" >
            <b-row>
                <b-col class="d-flex align-items-center justify-content-end">
                    <div
                        class="wrapper-icon-modal d-flex align-items-center justify-content-center"
                    >
                        <b-icon  @click="openedModalRemove = false" icon="x" ></b-icon>
                    </div>
                </b-col>
            </b-row>
            <div class="d-flex align-items-center justify-content-center flex-column">
                <h2>Remover Imagem</h2>
                <p class="my-4">Deseja realmente remover a imagem "{{ imagemToRemove.title }}"?</p>
                <a href="javascript:void(0)" class="btn btn-danger" @click="removeImage()">Remover</a>
            </div>
        </Modal>

        <Modal v-if="openedModalView" :openModal="openedModalView" :size="'lg'"  :classCustom="'modal-recuperar-senha'"  name="nome" >
            <b-row>
                <b-col class="d-flex align-items-center justify-content-end">
                    <div
                        class="wrapper-icon-modal d-flex align-items-center justify-content-center"
                    >
                        <b-icon  @click="openedModalView = false" icon="x" ></b-icon>
                    </div>
                </b-col>
            </b-row>
            <div class="d-flex align-items-center justify-content-center flex-column">
                <img :src="imagemView.url" class='img-fluid' alt="">
            </div>
        </Modal>
    </div>
</template>

<script>
import ImagemService from "../../../services/ImagemService";
import Utils from "../../../utils/utils.js";

export default {
    name: "Job",
    components: {
        Modal: () => import('../../../components/Modal'),
    },
    data() {
        return {
            imagens: [],
            file : null,
            imagemView : null,
            openedModalRemove: false,
            openedModalNew: false,
            openedModalView: false,
        }
    },
    methods: {
        openModalNew() {
            this.file = null;
            this.openedModalNew = true;
        },
        openModalRemove(imagem) {
            this.imagemToRemove = imagem;
            this.openedModalRemove = true;
        },
        openModalView(imagem) {
            this.imagemView = imagem;
            this.openedModalView = true;
        },
        setImage(event) {
            this.file = event.target.files[0];
            
        },
        saveImage() {
            this.Utils.swalLoading();
            var formData = new FormData()
            formData.append('file', this.file);

            this.ImagemService.new(formData)
            .then(() => {
                this.openedModalNew = false;
                this.Utils.success('Imagem enviada com sucesso!');
                this.loadImagens();
            }).catch(error => {
                this.openedModalNew = false;
                this.Utils.error(error.response.data.message);
            })

        },
        removeImage() {
            this.Utils.swalLoading();
            this.ImagemService.remove(this.imagemToRemove.id)
            .then(() => {
                this.openedModalRemove = false;
                this.Utils.success('Imagem removida com sucesso!');
                this.loadImagens();
            })
        },
        loadImagens() {
            this.ImagemService.getAll().then((response) => {
                this.imagens = response.data.data;
            })
        },
    },
    created() {
        this.Utils = new Utils();
        this.ImagemService = new ImagemService();
        this.loadImagens();
    }
}
</script>

<style scoped>

</style>
